import * as Sentry from '@sentry/nuxt';

const config = useRuntimeConfig().public;

Sentry.init({
  logErrors: true,

  dsn: config.SENTRY_DSN,
  environment: config.NODE_ENV,
  trackComponents: true,
  integrations: [
    Sentry.browserTracingIntegration({
      router: useRouter(),
    }),
    ...(config.NODE_ENV === 'production' ? [Sentry.replayIntegration()] : []),
    // new Sentry.Replay(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      autoInject: false,
      colorScheme: 'system',
      isNameRequired: true,
      isEmailRequired: true,
      triggerLabel: 'Signaler un bug',
      formTitle: 'Signaler un bug',
      submitButtonLabel: 'Envoyer',
      cancelButtonLabel: 'Annuler',
      confirmButtonLabel: 'Confirmer',
      addScreenshotButtonLabel: "Ajouter une capture d'écran",
      removeScreenshotButtonLabel: "Supprimer la capture d'écran",
      nameLabel: 'Nom',
      namePlaceholder: 'Votre nom',
      emailLabel: 'Email',
      emailPlaceholder: 'Votre email',
      isRequiredLabel: '*',
      messageLabel: 'Message',
      messagePlaceholder: "Décrivez le problème. Qu'attendiez vous ? Qu'est-ce qui s'est passé ?",
      successMessageText: 'Merci pour votre retour !',
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, log it.
    if (event.exception) {
      // eslint-disable-next-line no-console
      console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
    }
    // Continue sending to Sentry
    return event;
  },
});
